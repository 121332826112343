.edd-root,
.edd-root *,
.edd-root *::before,
.edd-root *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.edd-root {
    position: relative;
    width: 80px;
    user-select: none;
    font-family: 'Helvetica Neue', arial, helvetica, sans-serif;
    font-size: 16px;
    font-weight: 300;
    color: #333;
}

.edd-root-disabled {
    color: #ccc;
    cursor: not-allowed;
}

.edd-head {
    position: relative;
    overflow: hidden;
    transition: box-shadow 200ms;
    border: 1px solid #333;
    background: transparent;
}


.sticky-on-top .edd-head {
    border: 1px solid #333;
    background: white;
}

.edd-head,
.edd-body {
    border-radius: 20px;
}

.edd-root-focused .edd-head {
    border-color: #72a493;
}

.edd-root-invalid .edd-head {
    border-color: #ff6969;
}

.edd-value {
    width: 100%;
    display: inline-block;
    vertical-align: middle;
    padding: 10px 35px 10px 10px;
}

.edd-arrow {
    position: absolute;
    width: 18px;
    height: 10px;
    top: calc(50% - 5px);
    right: calc(25px - 9px);
    transition: transform 150ms;
    pointer-events: none;
    color: #888;
}

.edd-arrow::before {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    border-right: 1px solid currentColor;
    border-bottom: 1px solid currentColor;
    top: -5px;
    right: 0;
    transform: rotate(45deg);
    transform-origin: 50% 25%;
}

.edd-root-open .edd-arrow {
    transform: rotate(180deg);
}

.edd-root-open .edd-arrow,
.edd-root:not(.edd-root-disabled):not(.edd-root-open) .edd-head:hover .edd-arrow {
    color: #72a493;
}

.edd-value,
.edd-option,
.edd-group-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.edd-root:not(.edd-root-disabled) .edd-value,
.edd-option {
    cursor: pointer;
}

.edd-select {
    position: absolute;
    opacity: 0;
    width: 100%;
    left: -100%;
    top: 0;
}

.edd-root-native .edd-select {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.edd-body {
    opacity: 0;
    position: absolute;
    left: 0;
    right: 0;
    border: 1px solid #eee;
    pointer-events: none;
    overflow: hidden;
    margin: 8px 0;
    z-index: 999;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    transform: scale(0.95);
    background: white;
}

.edd-root-open .edd-body {
    opacity: 1;
    pointer-events: all;
    transform: scale(1);
    transition: opacity 200ms, transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.edd-root-open-above .edd-body {
    bottom: 100%;
}

.edd-root-open-below .edd-body {
    top: 100%;
}

.edd-items-list {
    overflow: auto;
    max-height: 0;
    transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
    -webkit-overflow-scrolling: touch;
}

.edd-group-label {
    font-size: 12px;
    font-weight: 400;
    padding: 12px 10px 4px;
}

.edd-option {
    padding: 6px 10px;
    border-bottom: 1px solid #eee;
    transition: background-color 250ms, color 250ms, border-color 250ms;
}

.edd-group-has-label .edd-option {
    padding-left: 14px;
}

.edd-option-selected {
    font-weight: 400;
    color: #72a493;
}

.edd-option-focused:not(.edd-option-disabled) {
    background: #72a493;
    border-bottom-color: #72a493;
    color: white;
}

.edd-option-disabled,
.edd-group-disabled .edd-option {
    cursor: default;
    color: #ccc;
}